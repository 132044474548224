<template>
  <div class="container">
    <img class="image" src="../assets/img/goeie.jpg" alt="Stefan Mars" />
    <h1 class="header">
      Hello, my name is Stefan Mars. I am a software development student from the Netherlands. Here you can see what I
      have
      made
    </h1>
  </div>

  <div class="container projects" id="projects">
    <div class="tile">
      <img class="thumbnail" src="../assets/img/image.jpg" alt="" />
      <a href="/">
        <h2>Project 1</h2>
      </a>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
    </div>
    <div class="tile">
      <img class="thumbnail" src="../assets/img/image.jpg" alt="" />
      <a href="/">
        <h2>Project 2</h2>
      </a>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
    </div>
    <div class="tile">
      <img class="thumbnail" src="../assets/img/image.jpg" alt="" />
      <a href="/">
        <h2>Project 3</h2>
      </a>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
    </div>
    <div class="tile">
      <img class="thumbnail" src="../assets/img/image.jpg" alt="" />
      <a href="/">
        <h2>Project 4</h2>
      </a>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
    </div>
  </div>

  <footer class="footer">

    <table class="contact">
      <tbody>
        <tr>
          <td>
            <a class="contact-link" href="mailto:stefanrmars@gmail.com">Stefanrmars@gmail.com</a>
          </td>
        </tr>
        <tr>
          <td>
            <a class="contact-link" href="https://www.linkedin.com/in/stefan-mars-4aa52a215/">LinkedIn</a>
          </td>
        </tr>
        <tr>
          <td>
            <a class="contact-link" href="https://github.com/Stefan-Mars">Github</a>
          </td>
        </tr>

      </tbody>
    </table>
    <br>

    <div>
      © 2024 Stefan Mars. All Rights Reserved.
    </div>
  </footer>
</template>


<script>
import 'primeicons/primeicons.css'

export default {
  components: {

  }
}
</script>
<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  padding-top: 20px;
  margin-top: 50px;
  overflow: hidden;
}

.tile {
  flex: 1 1 calc(33% - 40px);
  /* Default: Three items per row */
  max-width: 30%;
  background-color: #c7efcf;
  border-radius: 20px;
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thumbnail {
  border-radius: 30px 30px 0 0;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 15px;
}

.image {
  border-radius: 50%;
  border: 10px solid #fe5f55;
  width: 30%;
  height: auto;
  margin: auto;
  transform: scale(0%);
  animation: scaleUp 1.5s forwards;
}

@keyframes fromRight {
  from {
    transform: translateX(1000px);
  }

  to {
    transform: translateX(0px);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0%);
  }

  25% {
    transform: scale(25%);
  }

  50% {
    transform: scale(50%);
  }

  75% {
    transform: scale(75%);
  }

  100% {
    transform: scale(100%);
  }
}





.header {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  animation: fromRight 1.5s ease-out forwards;
  overflow: hidden;
}

.projects {
  margin-top: 100px;
  background-color: #333745;
  position: relative;
}

h2 {
  margin: 0px;
}

a {
  text-decoration: none;
  color: #333745;
}

p {
  padding: 20px;
  margin: 0px;
}

.footer {
  text-align: center;
  color: #eef5db;

  background-color: #333745;
  padding: 10px
}


.contact {
  font-size: 1rem;
  color: #eef5db;
  text-align: left;

}

.contact-link {
  color: #eef5db;
}

.contact-table {
  align-items: right;
}


@media screen and (max-width: 768px) {
  .header {
    font-size: 1rem;
  }

  .tile {
    flex: 1 1 calc(50% - 20px);
    /* Two items per row on smaller screens */
    max-width: 45%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 480px) {
  .tile {
    flex: 1 1 100%;
    /* One item per row on very small screens */
    max-width: 100%;
  }
}
</style>
