<template>

  <head>
    <meta charset="UTF-8">
    <meta name="description" content="Portfolio Stefan Mars">
    <meta name="keywords" content="HTML, CSS, JAVASCRIPT, PORTFOLIO">
    <meta name="author" content="Stefan Mars">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
  </head>
  <nav class="nav">
    <router-link to="/">
      <img class="profile-picture" src="../src/assets/img/profielfoto.png" alt="Profile Picture" />
    </router-link>
    <button class="menu-toggle" @click="toggleMenu">☰</button>
    <div :class="['links', { 'is-visible': isMenuOpen }]">
      <router-link class="link" to="/">
        <h1>Home</h1>
      </router-link>
      <a class="link justify-start" href="#projects">
        <h1>Projects</h1>
      </a>
      <a class="link" href="#contact">
        <h1>Contact</h1>
      </a>
    </div>
  </nav>
  <router-view />
</template>


<script>
export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Antic&family=Archivo+Black&family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Kodchasan:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Parkinsans:wght@300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333745;

}

html {
  scroll-behavior: smooth;
  background-color: #EEF5DB;
  margin: 0px;
  padding: 0px;

  background-image: repeating-linear-gradient(45deg,
      #C7EFCF 10px,
      #C7EFCF 12px,
      transparent 12px,
      transparent 20px);


}

body {
  margin: 0px;
  padding: 0px;
}

.nav {
  width: 100%;
  background-color: #333745;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: sticky;

}


.link {
  padding: 15px;
  text-decoration: none;
  color: #EEF5DB;
  transition: 0.5s;
  margin-right: 20px;
}

:hover.link {
  color: #FE5F55;
  transform: translateY(-5px);
  transition: 0.5s;

}

h1 {
  margin: 0px
}

.profile-picture {
  border-radius: 50%;
  padding: 10px;
  width: 30%;
  height: auto;
  margin-left: 50px;
  margin-right: 75px;

}

.justify-start {
  margin-right: auto;
}


.links {
  display: flex;
  gap: 20px;
  transition: max-height 0.3s ease;
}

.links.is-visible {
  max-height: 500px;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: #eef5db;
  cursor: pointer;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .links {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 0;
    overflow: hidden;
  }

  .menu-toggle {
    display: block;
  }

  .profile-picture {
    margin-left: 25px;
  }
}
</style>
